import styled from "styled-components";



export const Clock=styled.img` 
 width: 40px;
`;

export const ClockBox=styled.div` 
 display: flex;
 /* background-color: yellowgreen; */
 width: 100%;

`;