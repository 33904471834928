
export const ADD_TASK_REQ='ADD_TASK_REQ';
export const ADD_TASK_SUCC='ADD_TASK_SUCC';
export const ADD_TASK_FAIL='ADD_TASK_FAIL';



export const GET_TASK_REQ='GET_TASK_REQ';
export const GET_TASK_SUCC='GET_TASK_SUCC';
export const GET_TASK_FAIL='GET_TASK_FAIL';


export const UPDATE_TASKS_REQ='UPDATE_TASKS_REQ';
export const UPDATE_TASKS_SUCC='UPDATE_TASKS_SUCC';
export const UPDATE_TASKS_FAIL='UPDATE_TASKS_FAIL';



export const ADD_SUBTASKS_REQ='ADD_SUBTASKS_REQ';
export const ADD_SUBTASKS_SUCC='ADD_SUBTASKS_REQ';
export const ADD_SUBTASKS_FAIL="ADD_SUBTASKS_FAIL";



export const DELETE_SUBTASK_REQ="DELETE_SUBTASK_REQ";
export const DELETE_SUBTASK_SUCC="DELETE_SUBTASK_SUCC";
export const DELETE_SUBTASK_FAIL="DELETE_SUBTASK_FAIL";

